import { useRouter } from 'next/router'

import Footer from '@/components/base/Footer'

const Shared = (props) => {
    const { query } = useRouter()

    return (
        <main className="relative">
            <div className="flex">
                <div
                    className={`${
                        query.print ? 'w-1500' : 'w-900'
                    } m-auto mb-6 py-8 ipad:w-full ipad:px-[30px]`}
                >
                    {props.children}
                </div>
            </div>
            <Footer />
        </main>
    )
}

export default Shared
