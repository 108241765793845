import { useRouter } from 'next/router'

import Preloader from '@/components/base/Preloader'

import { useSession } from '@/contexts/Session'

const Shared = ({ children }) => {
    const { push, query, pathname } = useRouter()

    const { isAuthenticated } = useSession({
        required: false,
        onAuthenticated() {
            if (pathname === '/my-trip/[id]/review-contract-guest') {
                push(`/my-trip/${query?.id}/review-contract`)
            } else if (pathname === '/my-trip/[id]/flight-details-guest') {
                push(`/my-trip/${query?.id}/flight-details`)
            } else {
                push(`/`)
            }
        },
    })

    if (!isAuthenticated) {
        return children
    }

    return <Preloader></Preloader>
}

export default Shared
